// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAaQm3QiftP2sGDGUoRHq6V3ko9QGgDtAk",
  authDomain: "portfolio-15df5.firebaseapp.com",
  projectId: "portfolio-15df5",
  storageBucket: "portfolio-15df5.appspot.com",
  messagingSenderId: "424868200849",
  appId: "1:424868200849:web:62d0df21f5d6355fb5da30",
  measurementId: "G-0VJQZXB1RX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

export { app, db }